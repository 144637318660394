// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import { useForm } from "react-hook-form"
import db from "../firebaseConfig"
import firebase from "firebase/compat/app"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Contact = ({ data }) => {
  const images = {
    dusk_texure: convertToBgImage(getImage(data.dusk_texure)),
  }

  const [success, setSuccess] = useState(false)

  function toastHandleChange(newValue) {
    setSuccess(newValue)
  }

  const notify = () => toast.success("Thank you for getting in touch!")

  if (success) {
    notify()
  }

  return (
    <Layout>
      <Seo title="Contact" />
      <section>
        <BackgroundImage
          {...images.dusk_texure}
          className="h-screen bg-dusk overflow-hidden"
        >
          <div className="container h-full mx-auto">
            <div className="flex gap-20 h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col w-full md:w-1/2">
                  <div className="sm:text-6xl text-4xl sm:text-left text-center max-w-xl mb-1 text-white">
                    Interested in enabling your idea?
                  </div>
                  <div className="sm:text-2xl text-xl max-w-lg text-white font-light mt-4 sm:text-left text-center">
                    At Softmatter, our dynamic team has industry-leading
                    expertise in smart textiles and wearables - from design to
                    manufacture. We help you strategically enhance your offering
                    so that it has a profound, global impact.
                  </div>
                </div>
              </Fade>
              <Fade right>
                <div className="md:block hidden w-1/2 right-0 bottom-0 mt-32">
                  <ContactForm
                    success_state={success}
                    onChange={toastHandleChange}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-dusk md:hidden block">
          <div className="container mx-auto py-24">
            <ContactForm success_state={success} onChange={toastHandleChange} />
          </div>
        </div>
      </section>
      <section>
        <div className="bg-chalk py-24">
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
              <div className="border-r-2 border-l-2 py-10 border-l-transparent border-r-salmon">
                <div className="text-salmon text-xl font-firs-medium mb-1">
                  Drop by
                </div>
                <div className="text-dusk font-xl">
                  50 Foster Lane, Colombo 10, Sri Lanka
                </div>
              </div>
              <div className="border-r-2 border-l-2 py-10 border-l-transparent border-r-salmon">
                <div className="text-salmon text-xl font-firs-medium mb-1">
                  Drop us a note
                </div>
                <div className="text-dusk font-xl hover:underline">
                  <a href="mailto:softmatter@masholdings.com">
                    softmatter@masholdings.com
                  </a>
                </div>
              </div>
              <div className="border-r-2 border-l-2 py-10 border-r-salmon border-l-transparent lg:border-transparent">
                <div className="text-salmon text-xl font-firs-medium mb-1">
                  Find us on
                </div>
                <div className="flex items-center gap-3">
                  <div>
                    <StaticImage
                      className="cursor-pointer"
                      quality={100}
                      alt="Softmatter"
                      src="../images/contact/fb.png"
                    />
                  </div>
                  <div>
                    <StaticImage
                      className="cursor-pointer"
                      quality={100}
                      alt="Softmatter"
                      src="../images/contact/ig.png"
                    />
                  </div>
                  <div>
                    <StaticImage
                      className="cursor-pointer"
                      quality={100}
                      alt="Softmatter"
                      src="../images/contact/linked.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        {success && (
          <>
            <ToastContainer
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ContactImages {
    dusk_texure: file(relativePath: { eq: "textures/dusk_texure.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

const ContactForm = props => {
  const [loading, setLoading] = useState(false)
  // const [success, setSuccess] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  function parentState() {
    props.onChange(true)
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  function sendGridFirebase(data) {
    fetch(
      "https://us-central1-softmatter-website.cloudfunctions.net/contactRequest",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(data),
      }
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
  }

  const submitContactRequest = async data => {
    let newData = Object.assign({}, data)
    newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
    newData.isCompleted = false
    await db.collection("contacts").add(newData)
  }

  const onSubmit = async data => {
    setLoading(true)
    try {
      await submitContactRequest(data)
      sendGridFirebase(data)
      parentState(data)
      setLoading(false)
      // setSuccess(true)
      setSubmitError(null)
      reset()
    } catch {
      setLoading(false)
      // setSuccess(false)
      setSubmitError("Submit Error occurred!")
    }
  }

  return (
    <div className="">
      <div className="text-white text-4xl">Contact Us</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          {...register("name", {
            required: true,
            pattern: {
              value: /^[a-z ,.'-]+$/i,
              message: "You Can Only Use Letters",
            },
          })}
          className="border-none bg-chalk p-3 w-full focus:outline-none text-dusk"
        />
        {errors.name ? (
          <>
            {errors.name.message ? (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  {errors.name.message}
                </div>
              </>
            ) : (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  Name cannot be empty
                </div>
              </>
            )}
          </>
        ) : null}
        <input
          type="text"
          name="email"
          placeholder="Email"
          {...register("email", {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Email address is not valid",
            },
          })}
          className="border-none bg-chalk p-3 w-full focus:outline-none text-dusk mt-4"
        />
        {errors.email ? (
          <>
            {errors.email.message ? (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  {errors.email.message}
                </div>
              </>
            ) : (
              <>
                <div className="text-xs text-red-400 font-firs-light mt-1">
                  Email cannot be empty
                </div>
              </>
            )}
          </>
        ) : null}
        <textarea
          id=""
          placeholder="Message"
          name="message"
          {...register("message", {
            required: true,
          })}
          rows="10"
          className="border-none bg-chalk p-3 w-full focus:outline-none text-dusk mt-4 resize-none"
        ></textarea>
        {errors.message ? (
          <>
            <div className="text-xs text-red-400 font-firs-light mt-1 mb-5">
              Message cannot be empty
            </div>
          </>
        ) : null}
        <div className="flex items-start">
          <input
            type="checkbox"
            className=""
            name="checkbox"
            {...register("checkbox", {
              required: true,
            })}
          />
          <div className="text-xs ml-2 text-white">
            I accept the terms and conditions and acknowledge that my
            information will be used in accordance with
            <Link to="/terms-of-use">
              <span className="text-salmon underline text-xs pl-1 cursor-pointer">
                Softmatter Policy.
              </span>
            </Link>
          </div>
        </div>
        {errors.checkbox ? (
          <>
            <div className="text-xs text-red-400 font-firs-light mt-4">
              Please accept the terms and conditions and privacy policy
            </div>
          </>
        ) : null}
        <button
          type="submit"
          className="rounded-full p-3 text-white bg-salmon uppercase w-32 mt-8 hover:bg-salmon/50 border border-transparent hover:border-salmon duration-200 text-center text-xl"
        >
          {loading ? (
            <>
              <Spinner />
            </>
          ) : (
            <>SEND</>
          )}
        </button>
      </form>
    </div>
  )
}

const Spinner = () => {
  return (
    <>
      <svg
        role="status"
        className="inline w-7 h-7 text-white animate-spin dark:text-white/50 fill-white/50 dark:fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </>
  )
}

export default Contact
