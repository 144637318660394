// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import Axios from "axios"

const firebaseConfig = {
  apiKey: "AIzaSyBGMbnuEVVJChwXUAN2aIoB-uzRC5tAlEk",
  authDomain: "softmatter-website.firebaseapp.com",
  projectId: "softmatter-website",
  storageBucket: "softmatter-website.appspot.com",
  messagingSenderId: "934010461869",
  appId: "1:934010461869:web:4703b894456f86e916f024",
  measurementId: "G-DTELRKMN8B",
}

const app = firebase.initializeApp(firebaseConfig)

const db = app.firestore()

export default db
